import React from 'react';

export default function IndexSectionHowItWorks4() {
    return (
        <React.Fragment>
            <>
                <section id="start" className="py-24 lg:py-32 bg-white overflow-hidden">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap xl:items-center -m-8">
                            <div className="w-full md:w-1/2 p-8">
                                <img 
                                    className="mx-auto rounded-2xl transform hover:-translate-y-1 transition duration-500" 
                                    src="https://static.shuffle.dev/uploads/files/8d/8d4207e1b76f26446d5b92f0dd8fdaea8b575c7e/how-to-image.png" 
                                    alt="" 
                                />
                            </div>
                            <div className="w-full md:w-1/2 p-8">
                                <div className="max-w-xl">
                                    <h2 className="font-heading mb-24 text-6xl tracking-tighter">Getting Started with AI Connect</h2>
                                    <div className="flex flex-wrap -m-6">
                                        <div className="w-full p-6">
                                            <div className="flex flex-wrap -m-2">
                                                <div className="w-auto p-2">
                                                    <div 
                                                        className="flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 rounded-full bg-slate-700" 
                                                        style={{ letterSpacing: '-0.5px' }}
                                                    >
                                                        1
                                                    </div>
                                                </div>
                                                <div className="flex-1 p-2">
                                                    <p className="text-xl tracking-tight">
                                                        <b className="text-orange-500">Create Your User Account:</b> 
                                                        Get started in minutes by clicking the Let’s Get Started button below! That’s it—move on to the next step, and begin setting your account with AI Connect.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full p-6">
                                            <div className="flex flex-wrap -m-2">
                                                <div className="w-auto p-2">
                                                    <div 
                                                        className="flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 rounded-full bg-slate-700" 
                                                        style={{ letterSpacing: '-0.5px' }}
                                                    >
                                                        2
                                                    </div>
                                                </div>
                                                <div className="flex-1 p-2">
                                                    <p className="text-xl tracking-tight">
                                                        <b className="text-orange-500">Use Promo Code:</b> 
                                                        To unlock preferred pricing for your subscription, remember to use your promo codes! 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full p-6">
                                            <div className="flex flex-wrap -m-2">
                                                <div className="w-auto p-2">
                                                    <div 
                                                        className="flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 rounded-full bg-slate-700" 
                                                        style={{ letterSpacing: '-0.5px' }}
                                                    >
                                                        3
                                                    </div>
                                                </div>
                                                <div className="flex-1 p-2">
                                                    <p className="text-xl tracking-tight">
                                                        Follow the onboarding instructions to travel agency AI marketing bliss with your new best friend - <b>Toby</b>!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </React.Fragment>
    );
}