import React, { useState } from "react";

export default function IndexSectionPricing5() {
  const [isYearly, setIsYearly] = useState(true);

  return (
    <React.Fragment>
      <>
        <section
          id="pricing"
          className="py-24 lg:pt-32 lg:pb-36 overflow-hidden bg-slate-800"
        >
          {" "}
          <div className="container px-4 mx-auto">
            {" "}
            {/* Centered Pricing Toggle */}{" "}
            <div className="flex justify-end items-center mr-28 mb-8">
              {" "}
              <div className="flex">
                {" "}
                <div className="flex p-2 border border-gray-300 bg-gray-200 rounded-full">
                  {" "}
                  <button
                    className={`px-6 py-2.5 font-medium tracking-tight rounded-full transition duration-500 ${
                      isYearly
                        ? "bg-orange-500 text-white"
                        : "text-gray-700"
                    }`}
                    onClick={() => setIsYearly(true)}
                  >
                    Yearly
                  </button>{" "}
                  <button
                    className={`px-6 py-2.5 font-medium tracking-tight rounded-full transition duration-500 ${
                      !isYearly
                        ? "bg-orange-500 text-white"
                        : "text-gray-700"
                    }`}
                    onClick={() => setIsYearly(false)}
                  >
                    Monthly
                  </button>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            {/* Pricing Plans */}{" "}
            <div className="flex flex-wrap -m-8">
              {" "}
              <div className="w-full md:w-1/2 p-8">
                {" "}
                <div className="flex flex-col justify-between h-full max-w-xl">
                  {" "}
                  <div className="mb-16">
                    {" "}
                    <span className="inline-block max-w-max mb-6 px-3 py-1.5 text-sm text-white uppercase tracking-tight font-semibold bg-orange-500 rounded-full">
                      Pricing Plans
                    </span>{" "}
                    <h2 className="font-heading text-6xl text-white tracking-tighter">
                      Choose a plan for a more advanced business
                    </h2>{" "}
                  </div>{" "}
                  <div>
                    {" "}
                    <p className="mb-8 text-xl text-gray-400 tracking-tight">
                      Leverage the latest AI Models:
                    </p>{" "}
                    <ul className="flex flex-wrap -m-2.5">
                      {" "}
                      <li className="flex items-center p-2.5">
                        {" "}
                        <div className="mr-3.5 inline-block">
                          {" "}
                          <svg
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {" "}
                            <path
                              d="M2.25 9.25L7.25 14.25L19.75 1.75"
                              stroke="#FFFF"
                              strokeWidth={3}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />{" "}
                          </svg>{" "}
                        </div>{" "}
                        <p className="text-xl text-white tracking-tight">
                          Open AI's GPT
                        </p>{" "}
                      </li>{" "}
                      <li className="flex items-center p-2.5">
                        {" "}
                        <div className="mr-3.5 inline-block">
                          {" "}
                          <svg
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {" "}
                            <path
                              d="M2.25 9.25L7.25 14.25L19.75 1.75"
                              stroke="#FFFF"
                              strokeWidth={3}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />{" "}
                          </svg>{" "}
                        </div>{" "}
                        <p className="text-xl text-white tracking-tight">
                          Anthropic's Claude
                        </p>{" "}
                      </li>{" "}
                      <li className="flex items-center p-2.5">
                        {" "}
                        <div className="mr-3.5 inline-block">
                          {" "}
                          <svg
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {" "}
                            <path
                              d="M2.25 9.25L7.25 14.25L19.75 1.75"
                              stroke="#FFFF"
                              strokeWidth={3}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />{" "}
                          </svg>{" "}
                        </div>{" "}
                        <p className="text-xl text-white tracking-tight">
                          Google Gemini
                        </p>{" "}
                      </li>{" "}
                      <li className="flex items-center p-2.5">
                        {" "}
                        <div className="mr-3.5 inline-block">
                          {" "}
                          <svg
                            width={22}
                            height={16}
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {" "}
                            <path
                              d="M2.25 9.25L7.25 14.25L19.75 1.75"
                              stroke="#FFFF"
                              strokeWidth={3}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />{" "}
                          </svg>{" "}
                        </div>{" "}
                        <p className="text-xl text-white tracking-tight">
                          Voyager Social's Roxie (beta)
                        </p>{" "}
                      </li>{" "}
                    </ul>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* Annual Pricing Card */}{" "}
              <div className={`w-full md:w-1/2 p-8 ${isYearly ? 'block' : 'hidden'}`}>
                {" "}
                <div className="md:max-w-md mx-auto md:mr-0 bg-indigo-500 transform hover:-translate-y-2 rounded-2xl transition duration-500">
                  {" "}
                  <div className="p-12 pt-3 pr-3 bg-white rounded-2xl">
                    {" "}
                    <div className="flex justify-end">
                      {" "}
                      <span
                        className="inline-block mb-6 px-4 py-2 text-sm text-white tracking-tight font-medium bg-gray-600 rounded-full"
                        style={{
                          background: 'url("basko-assets/images/gradient.png")',
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        Most Popular
                      </span>{" "}
                    </div>{" "}
                    <div className="pr-9">
                      {" "}
                      <h4 className="font-heading mb-6 text-6xl tracking-tighter">
                        <span className="line-through">$450</span>{" "}
                        <span className="text-orange-500">$350</span>
                      </h4>
                      <p
                        className="mb-3 text-3xl font-semibold"
                        style={{ letterSpacing: "-0.5px" }}
                      >
                        per year
                      </p>{" "}
                      <p className="tracking-tight">
                        Ideal for any agency ready to get started leveraging AI
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="p-12 pb-11 bg-orange-500">
                    {" "}
                    <ul className="-m-1.5 mb-11">
                      {" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          7 day free trial
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          1 Admin User*
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Toby AI Chat
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          vsCreate Media Editor
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          vsLinkShare Customizer
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Toby AI Lighting Flows
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Expanded AI Agency Brand Profile
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          72 Hour Priority Support
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Cancel Anytime
                        </span>{" "}
                      </li>{" "}
                    </ul>{" "}
                    <a
                      className="inline-block w-full mb-4 px-5 py-4 text-center text-gray-700 font-semibold tracking-tight bg-white hover:bg-gray-100 rounded-lg focus:ring-4 focus:ring-gray-200 transition duration-200"
                      href="https://buy.stripe.com/dR65mQ6oM6tnd6UcMZ"
                    >
                      Try 7 Day Free Trial
                    </a>{" "}
                    <span className="text-sm text-white tracking-tight italic">
                      {/* *You can upgrade to a team account once you register your
                      first account */}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* Monthly Pricing Card */}{" "}
              <div className={`w-full md:w-1/2 p-8 ${!isYearly ? 'block' : 'hidden'}`}>
                {" "}
                <div className="md:max-w-md mx-auto md:mr-0 bg-indigo-500 transform hover:-translate-y-2 rounded-2xl transition duration-500">
                  {" "}
                  <div className="p-12 pt-3 pr-3 bg-white rounded-2xl">
                    {" "}
                    <div className="flex justify-end">
                      {" "}
                      <span
                        className="inline-block mb-6 px-4 py-2 text-sm text-white tracking-tight font-medium bg-gray-600 rounded-full"
                        style={{
                          background: 'url("basko-assets/images/gradient.png")',
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        Most Popular
                      </span>{" "}
                    </div>{" "}
                    <div className="pr-9">
                      {" "}
                      <h4 className="font-heading mb-6 text-6xl tracking-tighter">
                        <span className="line-through">$45</span>{" "}
                        <span className="text-orange-500">$35</span>
                      </h4>
                      <p
                        className="mb-3 text-3xl font-semibold"
                        style={{ letterSpacing: "-0.5px" }}
                      >
                        per month
                      </p>{" "}
                      <p className="tracking-tight">
                        Ideal for any agency ready to get started leveraging AI
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="p-12 pb-11 bg-orange-500">
                    {" "}
                    <ul className="-m-1.5 mb-11">
                      {" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          7 day free trial
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          1 Admin User*
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Toby AI Chat
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          vsCreate Media Editor
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          vsLinkShare Customizer
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Toby AI Lighting Flows
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Expanded AI Agency Brand Profile
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          72 Hour Priority Support
                        </span>{" "}
                      </li>{" "}
                      <li className="flex items-center p-1.5">
                        {" "}
                        <div className="mr-3 inline-block">
                          {" "}
                          <svg
                            width={7}
                            height={7}
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
                          </svg>{" "}
                        </div>{" "}
                        <span className="text-white font-medium tracking-tight">
                          Cancel Anytime
                        </span>{" "}
                      </li>{" "}
                    </ul>{" "}
                    <a
                      className="inline-block w-full mb-4 px-5 py-4 text-center text-gray-700 font-semibold tracking-tight bg-white hover:bg-gray-100 rounded-lg focus:ring-4 focus:ring-gray-200 transition duration-200"
                      href="https://buy.stripe.com/14keXq9AY8Bv1ocfZc"
                    >
                      Try 7 Day Free Trial
                    </a>{" "}
                    <span className="text-sm text-white tracking-tight italic">
                      {/* *You can upgrade to a team account once you register your
                      first account */}
                    </span>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
