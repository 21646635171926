import React from "react";

export default function IndexSectionHeaders1() {
  return (
    <React.Fragment>
      <>
        <section className="overflow-hidden ">
          {" "}
          <div className="bg-white">
            {" "}
            <div className="container px-4 mx-auto">
              {" "}
              <div className="flex items-center justify-between py-5">
                {" "}
                <div className="w-auto">
                  {" "}
                  <div className="flex flex-wrap items-center">
                    {" "}
                    <div className="w-auto pr-2">
                      {" "}
                      <a href="/">
                        {" "}
                        <img
                          src="https://static.shuffle.dev/uploads/files/8d/8d4207e1b76f26446d5b92f0dd8fdaea8b575c7e/ai-connect-logo.png"
                          alt
                        />{" "}
                      </a>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="w-auto">
                  {" "}
                  <div className="flex flex-wrap items-center">
                    {" "}
                    <div className="w-auto hidden lg:block">
                      {" "}
                      <ul className="flex items-center mr-8">
                        {" "}
                        <li className="mr-14 font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                          <a href="#features" className="hover:text-orange-500">
                            Features
                          </a>
                        </li>{" "}
                        <li className="mr-14 font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                          <a href="#start" className="hover:text-orange-500">
                            Get Started
                          </a>
                        </li>{" "}
                        <li className="mr-8 font-medium text-gray-700 hover:text-gray-200 tracking-tight border-r border-gray-700 pr-8">
                          <a href="#pricing" className="hover:text-orange-500">
                            Choose A Plan
                          </a>
                        </li>{" "}
                        <li className="font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                          <a
                            href="https://ai.aiconnecttools.com/login"
                            className="font-black hover:text-orange-500"
                          >
                            Login
                          </a>
                        </li>{" "}
                      </ul>{" "}
                    </div>{" "}
                    <div className="w-auto hidden lg:block">
                      {" "}
                      <div className="inline-block">
                        <a
                          className="inline-block px-5 py-3 text-white font-semibold text-center tracking-tight rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200 bg-orange-500 hover:bg-orange-300"
                          href="#pricing"
                        >
                          Try 7 Days Free Trial
                        </a>
                      </div>{" "}
                    </div>{" "}
                    <div className="w-auto lg:hidden">
                      {" "}
                      <button>
                        {" "}
                        <svg
                          className="text-indigo-600"
                          width={51}
                          height={51}
                          viewBox="0 0 56 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill="currentColor"
                          />{" "}
                          <path
                            d="M37 32H19M37 24H19"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />{" "}
                        </svg>{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="hidden fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
              {" "}
              <div className="fixed inset-0 bg-gray-800 opacity-80" />{" "}
              <nav className="relative z-10 px-9 pt-8 bg-black h-full overflow-y-auto">
                {" "}
                <div className="flex flex-wrap justify-between h-full">
                  {" "}
                  <div className="w-full">
                    {" "}
                    <div className="flex items-center justify-between -m-2">
                      {" "}
                      <div className="w-auto p-2">
                        {" "}
                        <a className="inline-block" href="#">
                          {" "}
                          <img
                            src="basko-assets/logos/basko-logo-light.png"
                            alt
                          />{" "}
                        </a>{" "}
                      </div>{" "}
                      <div className="w-auto p-2">
                        {" "}
                        <button className="text-gray-700">
                          {" "}
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {" "}
                            <path
                              d="M6 18L18 6M6 6L18 18"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />{" "}
                          </svg>{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="flex flex-col justify-center py-16 w-full">
                    {" "}
                    <ul>
                      {" "}
                      <li className="mb-12 font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                        <a href="#">Features</a>
                      </li>{" "}
                      <li className="mb-12 font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                        <a href="#">Pricing</a>
                      </li>{" "}
                      <li className="mb-12 font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                        <a href="#">Automation</a>
                      </li>{" "}
                      <li className="font-medium text-gray-700 hover:text-gray-200 tracking-tight">
                        <a href="#">Customer Login</a>
                      </li>{" "}
                    </ul>{" "}
                  </div>{" "}
                  <div className="flex flex-col justify-end w-full pb-8">
                    <a
                      className="inline-block px-5 py-3 text-gray-700 font-semibold text-center tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200"
                      href="#"
                    >
                      Try 14 Days Free Trial
                    </a>
                  </div>{" "}
                </div>{" "}
              </nav>{" "}
            </div>
          </div>{" "}
          <div className="pt-16 lg:pt-32 pb-24 lg:pb-52 overflow-hidden bg-slate-800">
            {" "}
            <div className="container px-4 mx-auto">
              {" "}
              <div className="flex flex-wrap -m-8">
                {" "}
                <div className="w-full md:w-1/2 p-8">
                  {" "}
                  <div className="md:max-w-lg">
                    {" "}
                    <span className="inline-block mb-8 px-3 py-1 text-white font-semibold rounded-full bg-orange-500">
                      #1 Brand Building Tool
                    </span>{" "}
                    <h1 className="font-heading mb-12 text-white tracking-tighter text-6xl">
                      Ready to embark on a new era for travel agencies
                    </h1>{" "}
                    <div className="flex flex-wrap -m-2 mb-14">
                      {" "}
                      <div className="w-auto p-2">
                        <a
                          className="inline-block px-5 py-4 text-white font-semibold tracking-tight rounded-lg focus:ring-4 focus:ring-indigo-400 transition duration-200 bg-orange-500 hover:bg-orange-300"
                          href="#pricing"
                        >
                          Try 7 Days Free Trial
                        </a>
                      </div>{" "}
                      <div className="w-auto p-2">
                        {" "}
                        <a
                          className="inline-flex items-center px-5 py-4 bg-transparent hover:bg-indigo-600 border border-gray-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200 hover:bg-orange-500"
                          href="#features"
                        >
                          {" "}
                          <div className="inline-block">
                            {" "}
                            <svg
                              width={18}
                              height={16}
                              viewBox="0 0 18 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-2.5"
                            >
                              {" "}
                              <path
                                d="M7.33437 1.33438L6.15625 1.775C6.0625 1.80938 6 1.9 6 2C6 2.1 6.0625 2.19063 6.15625 2.225L7.33437 2.66563L7.775 3.84375C7.80938 3.9375 7.9 4 8 4C8.1 4 8.19063 3.9375 8.225 3.84375L8.66562 2.66563L9.84375 2.225C9.9375 2.19063 10 2.1 10 2C10 1.9 9.9375 1.80938 9.84375 1.775L8.66562 1.33438L8.225 0.15625C8.19063 0.0625 8.1 0 8 0C7.9 0 7.80938 0.0625 7.775 0.15625L7.33437 1.33438ZM1.44062 12.3563C0.85625 12.9406 0.85625 13.8906 1.44062 14.4781L2.52187 15.5594C3.10625 16.1438 4.05625 16.1438 4.64375 15.5594L16.5594 3.64063C17.1438 3.05625 17.1438 2.10625 16.5594 1.51875L15.4781 0.440625C14.8937 -0.14375 13.9437 -0.14375 13.3562 0.440625L1.44062 12.3563ZM15.1438 2.58125L11.8625 5.8625L11.1344 5.13438L14.4156 1.85313L15.1438 2.58125ZM0.234375 3.6625C0.09375 3.71563 0 3.85 0 4C0 4.15 0.09375 4.28438 0.234375 4.3375L2 5L2.6625 6.76563C2.71562 6.90625 2.85 7 3 7C3.15 7 3.28438 6.90625 3.3375 6.76563L4 5L5.76562 4.3375C5.90625 4.28438 6 4.15 6 4C6 3.85 5.90625 3.71563 5.76562 3.6625L4 3L3.3375 1.23438C3.28438 1.09375 3.15 1 3 1C2.85 1 2.71562 1.09375 2.6625 1.23438L2 3L0.234375 3.6625ZM11.2344 11.6625C11.0938 11.7156 11 11.85 11 12C11 12.15 11.0938 12.2844 11.2344 12.3375L13 13L13.6625 14.7656C13.7156 14.9063 13.85 15 14 15C14.15 15 14.2844 14.9063 14.3375 14.7656L15 13L16.7656 12.3375C16.9062 12.2844 17 12.15 17 12C17 11.85 16.9062 11.7156 16.7656 11.6625L15 11L14.3375 9.23438C14.2844 9.09375 14.15 9 14 9C13.85 9 13.7156 9.09375 13.6625 9.23438L13 11L11.2344 11.6625Z"
                                fill="white"
                              />{" "}
                            </svg>{" "}
                          </div>{" "}
                          <span className="text-white font-semibold tracking-tight">
                            See What's Inside
                          </span>{" "}
                        </a>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="max-w-md">
                      {" "}
                      <div className="flex flex-wrap -m-1.5">
                        {" "}
                        <div className="w-auto p-1.5">
                          {" "}
                          <div className="flex flex-wrap">
                            {" "}
                            <div className="w-auto">
                              {" "}
                              <img
                                src="basko-assets/images/headers/avatar.png"
                                alt
                              />{" "}
                            </div>{" "}
                            <div className="w-auto -ml-5">
                              {" "}
                              <img
                                src="basko-assets/images/headers/avatar2.png"
                                alt
                              />{" "}
                            </div>{" "}
                            <div className="w-auto -ml-5">
                              {" "}
                              <img
                                src="basko-assets/images/headers/avatar3.png"
                                alt
                              />{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="flex-1 p-1.5">
                          {" "}
                          <p className="text-white tracking-tight">
                          Join the other happy 2k+ travel advisors and start transforming your agency today!
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="w-full md:w-1/2 p-8 mt-28">
                  {" "}
                  <img
                    className="transform hover:-translate-y-2 transition duration-500 rounded"
                    src="https://static.shuffle.dev/uploads/files/8d/8d4207e1b76f26446d5b92f0dd8fdaea8b575c7e/hero-image.png"
                    alt
                  />{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
