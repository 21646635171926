import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionFeatures2 from '../components/features/IndexSectionFeatures2';
import IndexSectionFeatures3 from '../components/features/IndexSectionFeatures3';
import IndexSectionHowItWorks4 from '../components/how-it-works/IndexSectionHowItWorks4';
import IndexSectionPricing5 from '../components/pricing/IndexSectionPricing5';
import IndexSectionFooters6 from '../components/footers/IndexSectionFooters6';

const meta = {
  title: 'AI\u0020CONNECT',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
      <IndexSectionFeatures2 />
      <IndexSectionFeatures3 />
      <IndexSectionHowItWorks4 />
      <IndexSectionPricing5 />
      <IndexSectionFooters6 />
    </React.Fragment>
  );
}

