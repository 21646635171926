import React from "react";

export default function IndexSectionFeatures2() {
  return (
    <React.Fragment>
      <>
        <section className="py-24 lg:py-44 overflow-hidden">
          {" "}
          <div className="container px-4 mx-auto">
            {" "}
            <div className="flex flex-wrap lg:items-center -m-8">
              {" "}
              <div className="w-full md:w-1/2 p-8">
                {" "}
                <div className="md:max-w-xl">
                  {" "}
                  <h2 className="font-heading mb-6 text-6xl tracking-tighter">
                    {" "}
                    Manage Your Travel Business with{" "}
                    <span className="text-orange-400">AI</span> Connect{" "}
                  </h2>{" "}
                  <p className="mb-5 tracking-tight">
                    Ready to embark on a new era for travel agencies? We're
                    excited to partner with Toby AI to bring you AI Connect!
                  </p>{" "}
                  <p className="mb-10 tracking-tight">
                    AI Connect is your all-in-one marketing portal, blending
                    TRAVELSAVERS exclusive supplier offers with the powerful insights
                    of Toby, expertly trained in travel.
                  </p>{" "}
                  <div className="flex flex-wrap -m-6">
                    {" "}
                    <div className="w-full md:w-1/2 p-6">
                      {" "}
                      <h3 className="mb-2 text-xl font-semibold tracking-tight">
                        Elevate
                      </h3>{" "}
                      <p className="tracking-tight">
                        Elevate your agency’s marketing game with tailored,
                        dynamic content designed to resonate with clients and
                        close more sales.
                      </p>{" "}
                    </div>{" "}
                    <div className="w-full md:w-1/2 p-6">
                      {" "}
                      <h3 className="mb-2 text-xl font-semibold tracking-tight">
                        Re-Imagine
                      </h3>{" "}
                      <p className="tracking-tight">
                        It’s time to re-imagine how you promote your services
                        and stand out in a competitive market!
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="w-full md:w-1/2 p-8">
                {" "}
                <div className="max-w-max mx-auto">
                  {" "}
                  <img
                    className="transform hover:-translate-y-2 transition duration-500"
                    src="https://static.shuffle.dev/uploads/files/8d/8d4207e1b76f26446d5b92f0dd8fdaea8b575c7e/toby-power.png"
                    alt
                  />{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
