import React from "react";

export default function IndexSectionFooters6() {
  return (
    <React.Fragment>
      <>
        <section className="py-9 bg-white overflow-hidden">
          {" "}
          <div className="container px-4 mx-auto">
            {" "}
            <div className="flex flex-wrap items-center justify-between -m-4">
              {" "}
              {/* First Column */}{" "}
              <div className="w-auto p-4">
                {" "}
                <div className="flex flex-wrap items-center -m-7 justify-center">
                  {" "}
                  <div className="w-auto p-7">
                    {" "}
                    <a href="#">
                      {" "}
                      <img
                        src="https://static.shuffle.dev/uploads/files/8d/8d4207e1b76f26446d5b92f0dd8fdaea8b575c7e/AIConnect-Logo.png"
                        alt="Logo"
                      />{" "}
                    </a>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* Second Column */}{" "}
              <div className="w-auto p-4">
                {" "}
                <div className="flex flex-wrap items-center -m-7 justify-center">
                  {" "}
                  <div className="w-auto p-7">
                    {" "}
                    <p className="text-sm text-gray-700 tracking-tight">
                      © Copyright {new Date().getFullYear()} TRAVELSAVERS | 71 Audrey Avenue, Oyster
                      Bay, NY 11771
                    </p>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* Third Column */}{" "}
              <div className="w-auto p-4">
                {" "}
                <div className="flex flex-wrap items-center justify-center -m-4">
                  {" "}
                  <ul className="flex flex-wrap -m-4">
                    {" "}
                    <li className="p-4">
                      <a
                        className="text-sm text-gray-700 hover:text-gray-300 tracking-tight transition duration-200"
                        href="https://app.voyagersocial.ai/terms"
                      >
                        Terms &amp; Conditions
                      </a>
                    </li>{" "}
                    <li className="p-4">
                      <a
                        className="text-sm text-gray-700 hover:text-gray-300 tracking-tight transition duration-200"
                        href="https://ai.aiconnecttools.com/login"
                      >
                        Login
                      </a>
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
